import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Link from "components/Link"
import Container from "components/Container"
import Page from "components/Page"

import styles from "./blog.module.scss"

const Blog = ({ data }) => {
  const { edges } = data.allMarkdownRemark

  return (
    <Page>
      <Container containerClassName={styles.container} className={styles.wrap}>
        <h1>
          Thoughts on efficiency
          <br />
          and collaboration in remote era
        </h1>

        <ul className={styles.posts}>
          {edges.map(edge => {
            const { frontmatter } = edge.node

            const excerpt =
              frontmatter.excerpt.length > 180
                ? frontmatter.excerpt.substr(0, 180) + "..."
                : frontmatter.excerpt

            return (
              <li key={frontmatter.path} style={{ marginBottom: "1rem" }}>
                <div>
                  <div>
                    <ul className={styles.tags}>
                      {frontmatter.tags.map(tag => (
                        <li>{tag}</li>
                      ))}
                    </ul>

                    <Link.BorderUnderlineHover
                      className={styles.title}
                      to={frontmatter.path}
                    >
                      {frontmatter.title}
                    </Link.BorderUnderlineHover>

                    <p>{excerpt}</p>
                  </div>

                  <div className={styles.author}>
                    <img src="/dv-avatar.png" alt="Logo" />
                    <p>
                      <Link.Underline to="/authors/dimitar-valchanov">
                        Dimitar Valchanov
                      </Link.Underline>
                      {"  "}• {frontmatter.date} • 6 min read
                    </p>
                  </div>
                </div>
                <Img fluid={frontmatter.featuredImage.childImageSharp.fluid} />
              </li>
            )
          })}
        </ul>

        {/* <div>
          <Link to="/tags">Browse by tag</Link>
        </div> */}
      </Container>
    </Page>
  )
}

export const query = graphql`
  query blogQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            date
            tags
            excerpt
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Blog
